/**
 * Import all of Bootstrap's CSS:
 */

@import "bootstrap/scss/bootstrap";

/**
 * Variables:
 */

$shakingCupsDarkGreen: #102409;
$shakingCupsLime: #a0f11b;
$shakingCupsCyan: #1bcaf1;
$shakingCupsDarkGreen: #0e1414;
$shakingCupsDarkGray: #0e1414;
$shakingCupsDark: #050707;
$shakingCupsBackGroundGunMetal: url("../img/gun_metal.png");
$TiltNeon: "Tilt Neon", sans-serif;
$Roboto: "Roboto", sans-serif;

/**
 * General:
 */

body,
html {
  height: 100%;
  margin: 0;
}

.on-top {
  z-index: 100;
}

h1,
h2,
h3,
h4 {
  font-family: $TiltNeon;
}

p {
  font-family: $Roboto;
  font-size: 20px;
}

.btn {
  font-family: $TiltNeon;
}

#custom-navbar-logo-size {
  width: 200px;
  height: auto;
}

.nav-link {
  color: darkgray;
}

.nav-link:hover {
  color: darkgreen;
}

#custom-nav-btn-color {
  background-color: darkgray;
}

.form-control {
  border-color: $success;
}

/**
 * Landing Page:
 */

.custom-background {
  background-image: $shakingCupsBackGroundGunMetal;

  height: 100%;

  background-position: center;
  background-repeat: repeat;
}

.custom-image-size {
  width: 500px;
  height: auto;
}

.custom-button-margin {
  margin: 20px 10px 0px 10px;
}

.toast {
  --bs-bg-opacity: 0.5;
}

#custom-no-drinking-image-size {
  width: 30px;
  height: auto;
}

/**
 * Recipes Page:
 */

.card {
  margin: 10px;
  min-width: 16rem;
  border-radius: 0;
}

.square-bg-image {
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  width: 200px;
  min-height: 200px;
}

.rectangle-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100px;
  min-height: 200px;
  margin-right: 10px;
}

#recipe-container {
  min-width: 50vw;
  overflow-y: scroll;
  background: $shakingCupsDark;
}

.bg-green {
  background-color: $shakingCupsLime;
}

.card-style-size:hover {
  border: solid 2px;
  border-color: #102409;
}

.drink-card-image {
  min-height: 110px;
}

/**
 * About Page:
 */

.header-custom-font {
  font-family: $Roboto;
  font-size: 35px;
}

#darkmode-devs-link {
  color: $shakingCupsCyan;
}

.custom-link-style {
  text-decoration: none;
}

/**
 * Footer:
 */

.footer-text-size {
  font-size: 15px;
}
